import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import "./layout.css"

const Header = ({ siteTitle }) => (
  <header
    style={{
      marginBottom: `1.45rem`,
    }}
    className="sticky-top"
  >
    <div>
    <Navbar expand="md">
      <Navbar.Brand style={{color: 'white'}}><Link to="/" style={{textDecoration: 'none', color: 'white'}}>CASSANDRA SESSA</Link></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Link to="/" style={{margin: '9px 40px 5px 0px', textDecoration: 'none', color: 'black', fontSize: '14px'}} activeStyle={{color: 'white'}}>Home</Link>
          <Link to="/about" style={{margin: '9px 40px 5px 0px', textDecoration: 'none', color: 'black', borderCollapse: 'white', fontSize: '14px'}}activeStyle={{color: 'white'}}>About</Link>
          <Link to="/projects" style={{margin: '9px 40px 5px 0px', textDecoration: 'none', color: 'black', fontSize: '14px'}}activeStyle={{color: 'white'}}>Projects</Link>
          <Link to="/videos" style={{margin: '9px 40px 5px 0px', textDecoration: 'none', color: 'black', fontSize: '14px'}}activeStyle={{color: 'white'}}>Videos</Link>
          <Link to="/contact" style={{margin: '9px 40px 5px 0px', textDecoration: 'none', color: 'black', fontSize: '14px'}}activeStyle={{color: 'white'}}>Contact</Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
